import {
	CLEAR_CARGO_MANAGEMENT_STATE,
	CARGO_IN_PRODUCTION_REQUEST,
	CARGO_IN_PRODUCTION_REQUEST_SUCCESS,
	CARGO_IN_PRODUCTION_REQUEST_ERROR,
	CARGO_IN_LOADING_REQUEST,
	CARGO_IN_LOADING_REQUEST_SUCCESS,
	CARGO_IN_LOADING_REQUEST_ERROR,
	CARGO_GATE_IN_REQUEST,
	CARGO_GATE_IN_REQUEST_SUCCESS,
	CARGO_GATE_IN_REQUEST_ERROR,
	CARGO_SHIPPED_CARGO_REQUEST,
	CARGO_SHIPPED_CARGO_REQUEST_SUCCESS,
	CARGO_SHIPPED_CARGO_REQUEST_ERROR,
	CARGO_DELIVERED_REQUEST,
	CARGO_DELIVERED_REQUEST_SUCCESS,
	CARGO_DELIVERED_REQUEST_ERROR,
	CARGO_GAT_OUT_REQUEST,
	CARGO_GAT_OUT_REQUEST_SUCCESS,
	CARGO_GAT_OUT_REQUEST_ERROR,
	CARGO_CONTRACTS_REQUEST,
	CARGO_CONTRACTS_REQUEST_SUCCESS,
	CARGO_CONTRACTS_REQUEST_ERROR,
	MAP_MARKERS_REQUEST,
	MAP_MARKERS_REQUEST_SUCCESS,
	MAP_MARKERS_REQUEST_ERROR,
	MARKERS_DETAIL_REQUEST,
	MARKERS_DETAIL_REQUEST_SUCCESS,
	MARKERS_DETAIL_REQUEST_ERROR,
	MAP_STATUS_REQUEST,
	MAP_STATUS_REQUEST_SUCCESS,
	MAP_STATUS_REQUEST_ERROR,
	GEOCODE_REVERSE_REQUEST,
	GEOCODE_REVERSE_REQUEST_ERROR,
	GEOCODE_REVERSE_REQUEST_SUCCESS,
} from './actions';

import api from '@/utils/api';

const { http } = api.getInstance();
const LOAD_MANAGEMENT_URL = '/loads';

const defaultState = () => ({
	cargoInProductionList: [],
	cargoInProductionPage: 0,
	cargoInProductionMax: 0,
	isLoadingProduction: '',

	cargoInLoadingList: [],
	cargoInLoadingPage: 0,
	cargoInLoadingMax: 0,
	isLoadingInLoad: '',

	cargoGateInList: [],
	cargoGateInPage: 0,
	cargoGateInMax: 0,
	isLoadingGateIn: '',

	cargoShippedList: [],
	cargoShippedPage: 0,
	cargoShippedMax: 0,
	isLoadingShipped: '',

	cargoDeliveredList: [],
	cargoDeliveredPage: 0,
	cargoDeliveredMax: 0,
	isLoadingDelivered: '',

	cargoGateOutList: [],
	cargoGateOutPage: 0,
	cargoGateOutMax: 0,
	isLoadingGateOut: '',

	cargoContractList: [],
	isLoadingCargoContract: '',

	mapMarkers: [],
	isLoadingMapMarkers: '',

	geocodeReverse: {},

	markerDetail: [],
	isLoadingMarkerDetail: '',

	mapStatus: [],
	isLoadingMapStatus: '',
});

const state = defaultState();

const getters = {
	cargoInProductionList: (state) => state.cargoInProductionList,
	cargoInProductionPage: (state) => state.cargoInProductionPage,
	cargoInProductionMax: (state) => state.cargoInProductionMax,
	isLoadingProduction: (state) => state.isLoadingProduction,

	cargoInLoadingList: (state) => state.cargoInLoadingList,
	cargoInLoadingPage: (state) => state.cargoInLoadingPage,
	cargoInLoadingMax: (state) => state.cargoInLoadingMax,
	isLoadingInLoad: (state) => state.isLoadingInLoad,

	cargoGateInList: (state) => state.cargoGateInList,
	cargoGateInPage: (state) => state.cargoGateInPage,
	cargoGateInMax: (state) => state.cargoGateInMax,
	isLoadingGateIn: (state) => state.isLoadingGateIn,

	cargoShippedList: (state) => state.cargoShippedList,
	cargoShippedPage: (state) => state.cargoShippedPage,
	cargoShippedMax: (state) => state.cargoShippedMax,
	isLoadingShipped: (state) => state.isLoadingShipped,

	cargoDeliveredList: (state) => state.cargoDeliveredList,
	cargoDeliveredPage: (state) => state.cargoDeliveredPage,
	cargoDeliveredMax: (state) => state.cargoDeliveredMax,
	isLoadingDelivered: (state) => state.isLoadingDelivered,

	cargoGateOutList: (state) => state.cargoGateOutList,
	cargoGateOutPage: (state) => state.cargoGateOutPage,
	cargoGateOutMax: (state) => state.cargoGateOutMax,
	isLoadingGateOut: (state) => state.isLoadingGateOut,

	cargoContractList: (state) => state.cargoContractList,
	isLoadingCargoContract: (state) => state.isLoadingCargoContract,

	mapMarkers: (state) => state.mapMarkers,
	isLoadingMapMarkers: (state) => state.isLoadingMapMarkers,

	markerDetail: (state) => state.markerDetail,
	isLoadingMarkerDetail: (state) => state.isLoadingMarkerDetail,

	mapStatus: (state) => state.mapStatus,
	isLoadingMapStatus: (state) => state.isLoadingMapStatus,

	geocodeReverse: (state) => state.geocodeReverse,
};

const mutations = {
	[CLEAR_CARGO_MANAGEMENT_STATE]: (state) => {
		Object.assign(state, defaultState());
	},

	[CARGO_IN_PRODUCTION_REQUEST]: (state) => {
		state.isLoadingProduction = 'loading';
	},
	[CARGO_IN_PRODUCTION_REQUEST_SUCCESS]: (state, data) => {
		state.cargoInProductionPage = data.meta.currentPage;
		state.cargoInProductionMax = data.meta.totalPages;
		data.items.forEach((item) => {
			state.cargoInProductionList.push(item);
		});

		state.isLoadingProduction = 'success';
		if (data.meta.totalItems == 0) {
			state.isLoadingProduction = 'empty';
		}
	},
	[CARGO_IN_PRODUCTION_REQUEST_ERROR]: (state) => {
		state.cargoInProductionList = [];
		state.isLoadingProduction = 'error';
	},

	[CARGO_IN_LOADING_REQUEST]: (state) => {
		state.isLoadingInLoad = 'loading';
	},
	[CARGO_IN_LOADING_REQUEST_SUCCESS]: (state, data) => {
		state.cargoInLoadingPage = data.meta.currentPage;
		state.cargoInLoadingMax = data.meta.totalPages;
		data.items.forEach((item) => {
			state.cargoInLoadingList.push(item);
		});

		state.isLoadingInLoad = 'success';
		if (data.meta.totalItems == 0) {
			state.isLoadingInLoad = 'empty';
		}
	},
	[CARGO_IN_LOADING_REQUEST_ERROR]: (state) => {
		state.cargoInLoadingList = [];
		state.isLoadingInLoad = 'error';
	},

	[CARGO_GATE_IN_REQUEST]: (state) => {
		state.isLoadingGateIn = 'loading';
	},
	[CARGO_GATE_IN_REQUEST_SUCCESS]: (state, data) => {
		state.cargoGateInPage = data.meta.currentPage;
		state.cargoGateInMax = data.meta.totalPages;
		data.items.forEach((item) => {
			state.cargoGateInList.push(item);
		});

		state.isLoadingGateIn = 'success';
		if (data.meta.totalItems == 0) {
			state.isLoadingGateIn = 'empty';
		}
	},
	[CARGO_GATE_IN_REQUEST_ERROR]: (state) => {
		state.cargoGateInList = [];
		state.isLoadingGateIn = 'error';
	},

	[CARGO_SHIPPED_CARGO_REQUEST]: (state) => {
		state.isLoadingShipped = 'loading';
	},
	[CARGO_SHIPPED_CARGO_REQUEST_SUCCESS]: (state, data) => {
		state.cargoShippedPage = data.meta.currentPage;
		state.cargoShippedMax = data.meta.totalPages;
		data.items.forEach((item) => {
			state.cargoShippedList.push(item);
		});

		state.isLoadingShipped = 'success';
		if (data.meta.totalItems == 0) {
			state.isLoadingShipped = 'empty';
		}
	},
	[CARGO_SHIPPED_CARGO_REQUEST_ERROR]: (state) => {
		state.cargoShippedList = [];
		state.isLoadingShipped = 'error';
	},

	[CARGO_DELIVERED_REQUEST]: (state) => {
		state.isLoadingDelivered = 'loading';
	},
	[CARGO_DELIVERED_REQUEST_SUCCESS]: (state, data) => {
		state.cargoDeliveredPage = data.meta.currentPage;
		state.cargoDeliveredMax = data.meta.totalPages;
		data.items.forEach((item) => {
			state.cargoDeliveredList.push(item);
		});

		state.isLoadingDelivered = 'success';
		if (data.meta.totalItems == 0) {
			state.isLoadingDelivered = 'empty';
		}
	},
	[CARGO_DELIVERED_REQUEST_ERROR]: (state) => {
		state.cargoDeliveredList = [];
		state.isLoadingDelivered = 'error';
	},

	[CARGO_GAT_OUT_REQUEST]: (state) => {
		state.isLoadingGateOut = 'loading';
	},
	[CARGO_GAT_OUT_REQUEST_SUCCESS]: (state, data) => {
		state.cargoGateOutPage = data.meta.currentPage;
		state.cargoGateOutMax = data.meta.totalPages;
		data.items.forEach((item) => {
			state.cargoGateOutList.push(item);
		});

		state.isLoadingGateOut = 'success';
		if (data.meta.totalItems == 0) {
			state.isLoadingGateOut = 'empty';
		}
	},
	[CARGO_GAT_OUT_REQUEST_ERROR]: (state) => {
		state.cargoGateOutList = [];
		state.isLoadingGateOut = 'error';
	},

	[CARGO_CONTRACTS_REQUEST]: (state) => {
		state.isLoadingCargoContract = 'loading';
	},
	[GEOCODE_REVERSE_REQUEST_SUCCESS]: (state, data) => {
		state.geocodeReverse = data;
	},
	GEOCODE_REVERSE_REQUEST_ERROR: (state) => {
		state.geocodeReverse = {};
	},
	[CARGO_CONTRACTS_REQUEST_SUCCESS]: (state, data) => {
		let incomingData = {
			loadNumber: data.loadNumber,
			data: data.response,
		};

		const currentData = state.cargoContractList.filter(
			(item) => item.loadNumber != data.loadNumber,
		);
		currentData.push(incomingData);

		state.cargoContractList = currentData;
		state.isLoadingCargoContract = 'success';
		if (data.length == 0) {
			state.isLoadingCargoContract = 'empty';
		}
	},
	[CARGO_CONTRACTS_REQUEST_ERROR]: (state) => {
		state.cargoContractList = [];
		state.isLoadingCargoContract = 'error';
	},

	[MAP_MARKERS_REQUEST]: (state) => {
		state.isLoadingMapMarkers = 'loading';
	},
	[MAP_MARKERS_REQUEST_SUCCESS]: (state, data) => {
		data.forEach((item) => {
			if (item.portName !== null && item.latitude !== null && item.longitude !== null) {
				state.mapMarkers.push(item);
			}
		});

		state.isLoadingMapMarkers = 'success';
		if (data.length == 0) {
			state.isLoadingMapMarkers = 'empty';
		}
	},
	[MAP_MARKERS_REQUEST_ERROR]: (state) => {
		state.mapMarkers = [];
		state.isLoadingMapMarkers = 'error';
	},

	[MARKERS_DETAIL_REQUEST]: (state) => {
		state.isLoadingMarkerDetail = 'loading';
	},
	[MARKERS_DETAIL_REQUEST_SUCCESS]: (state, data) => {
		state.markerDetail = data;

		state.isLoadingMarkerDetail = 'success';
		if (data.length == 0) {
			state.isLoadingMarkerDetail = 'empty';
		}
	},
	[MARKERS_DETAIL_REQUEST_ERROR]: (state) => {
		state.markerDetail = [];
		state.isLoadingMarkerDetail = 'error';
	},

	[MAP_STATUS_REQUEST]: (state) => {
		state.isLoadingMapStatus = 'loading';
	},
	[MAP_STATUS_REQUEST_SUCCESS]: (state, data) => {
		state.mapStatus = data;

		state.isLoadingMapStatus = 'success';
		if (data.length == 0) {
			state.isLoadingMapStatus = 'empty';
		}
	},
	[MAP_STATUS_REQUEST_ERROR]: (state) => {
		state.markerDetail = [];
		state.isLoadingMapStatus = 'error';
	},
};

const actions = {
	[CARGO_IN_PRODUCTION_REQUEST]: (
		{ commit },
		{
			vm,
			companyId,
			page = 1,
			contractNumberSequence = '',
			purchaseOrder = '',
			loadNumber = '',
			vessel = '',
			container = '',
			product = '',
			expectedEtbDate = '',
			realAtbDate = '',
			vehicleType = 'ship',
			limit = 10,
		},
	) => {
		return new Promise((resolve) => {
			commit(CARGO_IN_PRODUCTION_REQUEST);

			let url = `${LOAD_MANAGEMENT_URL}?companyId=${companyId}&page=${page}&limit=${limit}&status=Em produção&contractNumberSequence=${contractNumberSequence}&purchaseOrder=${purchaseOrder}&loadNumber=${loadNumber}&vessel=${vessel}&container=${container}&product=${product}&expectedEtbDate=${expectedEtbDate}&realAtbDate=${realAtbDate}&vehicleType=${vehicleType}`;

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(CARGO_IN_PRODUCTION_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(CARGO_IN_PRODUCTION_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[CARGO_IN_LOADING_REQUEST]: (
		{ commit },
		{
			vm,
			companyId,
			page = 1,
			contractNumberSequence = '',
			purchaseOrder = '',
			loadNumber = '',
			vessel = '',
			container = '',
			product = '',
			expectedEtbDate = '',
			realAtbDate = '',
			vehicleType = 'ship',
			limit = 10,
		},
	) => {
		return new Promise((resolve) => {
			commit(CARGO_IN_LOADING_REQUEST);

			let url = `${LOAD_MANAGEMENT_URL}?companyId=${companyId}&page=${page}&limit=${limit}&status=Em carregamento&contractNumberSequence=${contractNumberSequence}&purchaseOrder=${purchaseOrder}&loadNumber=${loadNumber}&vessel=${vessel}&container=${container}&product=${product}&expectedEtbDate=${expectedEtbDate}&realAtbDate=${realAtbDate}&vehicleType=${vehicleType}`;

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(CARGO_IN_LOADING_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(CARGO_IN_LOADING_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[CARGO_GATE_IN_REQUEST]: (
		{ commit },
		{
			vm,
			companyId,
			page = 1,
			contractNumberSequence = '',
			purchaseOrder = '',
			loadNumber = '',
			vessel = '',
			container = '',
			product = '',
			expectedEtbDate = '',
			realAtbDate = '',
			vehicleType = 'ship',
			limit = 10,
		},
	) => {
		return new Promise((resolve) => {
			commit(CARGO_GATE_IN_REQUEST);

			let url = `${LOAD_MANAGEMENT_URL}?companyId=${companyId}&page=${page}&limit=${limit}&status=Gate in origem&contractNumberSequence=${contractNumberSequence}&purchaseOrder=${purchaseOrder}&loadNumber=${loadNumber}&vessel=${vessel}&container=${container}&product=${product}&expectedEtbDate=${expectedEtbDate}&realAtbDate=${realAtbDate}&vehicleType=${vehicleType}`;

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(CARGO_GATE_IN_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(CARGO_GATE_IN_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[CARGO_SHIPPED_CARGO_REQUEST]: (
		{ commit },
		{
			vm,
			companyId,
			page = 1,
			contractNumberSequence = '',
			purchaseOrder = '',
			loadNumber = '',
			vessel = '',
			container = '',
			product = '',
			expectedEtbDate = '',
			realAtbDate = '',
			vehicleType = 'ship',
			limit = 10,
		},
	) => {
		return new Promise((resolve) => {
			commit(CARGO_SHIPPED_CARGO_REQUEST);

			let url = `${LOAD_MANAGEMENT_URL}?companyId=${companyId}&page=${page}&limit=${limit}&status=Embarcado&contractNumberSequence=${contractNumberSequence}&purchaseOrder=${purchaseOrder}&loadNumber=${loadNumber}&vessel=${vessel}&container=${container}&product=${product}&expectedEtbDate=${expectedEtbDate}&realAtbDate=${realAtbDate}&vehicleType=${vehicleType}`;

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(CARGO_SHIPPED_CARGO_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(CARGO_SHIPPED_CARGO_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[CARGO_DELIVERED_REQUEST]: (
		{ commit },
		{
			vm,
			companyId,
			page = 1,
			contractNumberSequence = '',
			purchaseOrder = '',
			loadNumber = '',
			vessel = '',
			container = '',
			product = '',
			expectedEtbDate = '',
			realAtbDate = '',
			vehicleType = 'ship',
			limit = 10,
		},
	) => {
		return new Promise((resolve) => {
			commit(CARGO_DELIVERED_REQUEST);

			let url = `${LOAD_MANAGEMENT_URL}?companyId=${companyId}&page=${page}&limit=${limit}&status=Entregue no POD&contractNumberSequence=${contractNumberSequence}&purchaseOrder=${purchaseOrder}&loadNumber=${loadNumber}&vessel=${vessel}&container=${container}&product=${product}&expectedEtbDate=${expectedEtbDate}&realAtbDate=${realAtbDate}&vehicleType=${vehicleType}`;

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(CARGO_DELIVERED_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(CARGO_DELIVERED_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[CARGO_GAT_OUT_REQUEST]: (
		{ commit },
		{
			vm,
			companyId,
			page = 1,
			contractNumberSequence = '',
			purchaseOrder = '',
			loadNumber = '',
			vessel = '',
			container = '',
			product = '',
			expectedEtbDate = '',
			realAtbDate = '',
			vehicleType = 'ship',
			limit = 10,
		},
	) => {
		return new Promise((resolve) => {
			commit(CARGO_GAT_OUT_REQUEST);

			let url = `${LOAD_MANAGEMENT_URL}?companyId=${companyId}&page=${page}&limit=${limit}&status=Gate out destino&contractNumberSequence=${contractNumberSequence}&purchaseOrder=${purchaseOrder}&loadNumber=${loadNumber}&vessel=${vessel}&container=${container}&product=${product}&expectedEtbDate=${expectedEtbDate}&realAtbDate=${realAtbDate}&vehicleType=${vehicleType}`;

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(CARGO_GAT_OUT_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(CARGO_GAT_OUT_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[CARGO_CONTRACTS_REQUEST]: ({ commit }, { vm, loadNumber }) => {
		return new Promise((resolve) => {
			commit(CARGO_CONTRACTS_REQUEST);

			let url = `${LOAD_MANAGEMENT_URL}/${loadNumber}`;

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(CARGO_CONTRACTS_REQUEST_SUCCESS, {
						response: response.data,
						loadNumber: loadNumber,
					});
					resolve(response);
				})
				.catch((error) => {
					commit(CARGO_CONTRACTS_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[MAP_MARKERS_REQUEST]: (
		{ commit },
		{
			vm,
			companyId,
			page = 1,
			contractNumberSequence = '',
			purchaseOrder = '',
			loadNumber = '',
			vessel = '',
			container = '',
			product = '',
			expectedEtbDate = '',
			realAtbDate = '',
			isShip,
			limit = 10,
		},
	) => {
		return new Promise((resolve) => {
			commit(MAP_MARKERS_REQUEST);

			let url = `${LOAD_MANAGEMENT_URL}/${
				!isShip ? 'loadTruckCountMarker' : 'loadCountMarker'
			}?companyId=${companyId}&page=${page}&limit=${limit}&status=Gate out destino&contractNumberSequence=${contractNumberSequence}&purchaseOrder=${purchaseOrder}&loadNumber=${loadNumber}&vessel=${vessel}&container=${container}&product=${product}&expectedEtbDate=${expectedEtbDate}&realAtbDate=${realAtbDate}`;

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(MAP_MARKERS_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(MAP_MARKERS_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[MARKERS_DETAIL_REQUEST]: (
		{ commit },
		{
			vm,
			companyId,
			port,
			contractNumberSequence = '',
			purchaseOrder = '',
			loadNumber = '',
			vessel = '',
			container = '',
			product = '',
			expectedEtbDate = '',
			realAtbDate = '',
			vehicleType = '',
			page = 1,
			limit = 10,
		},
	) => {
		return new Promise((resolve) => {
			commit(MARKERS_DETAIL_REQUEST);

			let url = `${LOAD_MANAGEMENT_URL}/loadMarker?companyId=${companyId}&page=${page}&limit=${limit}&port=${port}&contractNumberSequence=${contractNumberSequence}&purchaseOrder=${purchaseOrder}&loadNumber=${loadNumber}&vessel=${vessel}&container=${container}&product=${product}&expectedEtbDate=${expectedEtbDate}&realAtbDate=${realAtbDate}&vehicleType=${vehicleType}`;

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(MARKERS_DETAIL_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(MARKERS_DETAIL_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[MAP_STATUS_REQUEST]: ({ commit }, { vm, companyId, page = 1, limit = 10, vehicleType = '' }) => {
		return new Promise((resolve) => {
			commit(MAP_STATUS_REQUEST);

			let url = `${LOAD_MANAGEMENT_URL}/loadStatus?companyId=${companyId}&page=${page}&limit=${limit}&vehicleType=${vehicleType}`;

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(MAP_STATUS_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(MAP_STATUS_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[CLEAR_CARGO_MANAGEMENT_STATE]: ({ commit }) => {
		commit(CLEAR_CARGO_MANAGEMENT_STATE);
	},
	[GEOCODE_REVERSE_REQUEST]: ({ commit }, { vm, latitude, longitude }) => {
		return new Promise((resolve) => {
			commit(GEOCODE_REVERSE_REQUEST);

			let url = `${LOAD_MANAGEMENT_URL}/reverseGeocode?latitude=${latitude}&longitude=${longitude}`;

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(GEOCODE_REVERSE_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(GEOCODE_REVERSE_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
