<template>
	<svg width="28" height="39" viewBox="0 0 28 39" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M0 13.6576C0 6.14591 6.15042 0 13.6676 0C21.1848 0 27.3352 6.07004 27.3352 13.6576C27.3352 25.3424 14.7306 38.2412 14.1991 38.7724C14.0472 38.9241 13.8954 39 13.6676 39C13.4398 39 13.2879 38.9241 13.1361 38.7724C12.6046 38.2412 0 25.4183 0 13.6576Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M21.0769 11.875L22.1697 14.6071C22.2979 14.9276 22.6616 15.0835 22.9821 14.9553C23.3026 14.8271 23.4585 14.4634 23.3303 14.1429L22.2376 11.4112C22.2374 11.4107 22.2372 11.4101 22.237 11.4096C22.1449 11.1772 21.9848 10.9779 21.7776 10.8379C21.5706 10.698 21.3262 10.6239 21.0764 10.625H21.0781V11.25L21.0741 10.625C21.0749 10.625 21.0756 10.625 21.0764 10.625"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M4.625 16.25C4.625 15.9048 4.90482 15.625 5.25 15.625H17.75C18.0952 15.625 18.375 15.9048 18.375 16.25C18.375 16.5952 18.0952 16.875 17.75 16.875H5.25C4.90482 16.875 4.625 16.5952 4.625 16.25Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M17.75 11.25C17.75 10.9048 17.8263 10.625 17.9205 10.625H21.3295C21.4237 10.625 21.5 10.9048 21.5 11.25C21.5 11.5952 21.4237 11.875 21.3295 11.875H17.9205C17.8263 11.875 17.75 11.5952 17.75 11.25Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M18.6875 18.75C17.9971 18.75 17.4375 19.3096 17.4375 20C17.4375 20.6904 17.9971 21.25 18.6875 21.25C19.3779 21.25 19.9375 20.6904 19.9375 20C19.9375 19.3096 19.3779 18.75 18.6875 18.75ZM16.1875 20C16.1875 18.6193 17.3068 17.5 18.6875 17.5C20.0682 17.5 21.1875 18.6193 21.1875 20C21.1875 21.3807 20.0682 22.5 18.6875 22.5C17.3068 22.5 16.1875 21.3807 16.1875 20Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M9.3125 18.75C8.62214 18.75 8.0625 19.3096 8.0625 20C8.0625 20.6904 8.62214 21.25 9.3125 21.25C10.0029 21.25 10.5625 20.6904 10.5625 20C10.5625 19.3096 10.0029 18.75 9.3125 18.75ZM6.8125 20C6.8125 18.6193 7.93179 17.5 9.3125 17.5C10.6932 17.5 11.8125 18.6193 11.8125 20C11.8125 21.3807 10.6932 22.5 9.3125 22.5C7.93179 22.5 6.8125 21.3807 6.8125 20Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M10.5625 20C10.5625 19.6548 10.8423 19.375 11.1875 19.375H16.8125C17.1577 19.375 17.4375 19.6548 17.4375 20C17.4375 20.3452 17.1577 20.625 16.8125 20.625H11.1875C10.8423 20.625 10.5625 20.3452 10.5625 20Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M4.99112 9.74112C5.22554 9.5067 5.54348 9.375 5.875 9.375H17.75C18.0952 9.375 18.375 9.65482 18.375 10V18.375C18.375 18.7202 18.0952 19 17.75 19C17.4048 19 17.125 18.7202 17.125 18.375V10.625L5.875 10.625V19.375H7.4375C7.78268 19.375 8.0625 19.6548 8.0625 20C8.0625 20.3452 7.78268 20.625 7.4375 20.625H5.875C5.54348 20.625 5.22554 20.4933 4.99112 20.2589C4.7567 20.0245 4.625 19.7065 4.625 19.375V10.625C4.625 10.2935 4.7567 9.97554 4.99112 9.74112Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M17.125 14.375C17.125 14.0298 17.4048 13.75 17.75 13.75H22.75C23.0952 13.75 23.375 14.0298 23.375 14.375V19.375C23.375 19.7065 23.2433 20.0245 23.0089 20.2589C22.7745 20.4933 22.4565 20.625 22.125 20.625H20.5625C20.2173 20.625 19.9375 20.3452 19.9375 20C19.9375 19.6548 20.2173 19.375 20.5625 19.375H22.125V15H17.75C17.4048 15 17.125 14.7202 17.125 14.375Z"
			fill="white"
		/>
	</svg>
</template>

<script>
export default {
	name: 'TruckPinIcon',
	props: {
		color: {
			type: String,
			default: '#fff',
		},
	},
};
</script>
